import '~/assets/styles/index.scss';

import React from 'react';
import { GeoLocationProvider } from '~/app/context';

export const wrapRootElement = ({ element }) => {
  return <GeoLocationProvider>{element}</GeoLocationProvider>;
};

setTimeout(() => {
  if (document.getElementById('form-rent-la')) {
    (window.__ctm_loaded || []).push(function () {
      __ctm.form.capture(
        'app.calltrackingmetrics.com', // the capture host
        'FRT472ABB2C5B9B141A3A4E031643917729E7D35BB85B2547A8C77D8150F18ECD9E', // this FormReactor
        document.getElementById('form-rent-la'), // ID of the web form you want to attach your FormReactor
        '8773052555', // the tracking number used for associating visitor data and caller ID consumers will see
        {
          country_code: '1',
          name: 'name', // set to the ID of the lead name field, or pass in a list of IDs if there are multiple name fields
          phone: 'phone', // ID of the lead phone number field
          email: 'email',
          fields: [
            'led_wall_size',
            'drop_of_date',
            'drop_of_time',
            'pick_up_date',
            'pick_up_time',
            'address',
            'city',
            'state',
            'zip_code',
            'comment',
          ], // list of additional field IDs in the form you want to capture
        }
      );
    });
  } else if (document.getElementById('form-rent-mi')) {
    (window.__ctm_loaded || []).push(function () {
      __ctm.form.capture(
        'app.calltrackingmetrics.com', // the capture host
        'FRT472ABB2C5B9B141A3A4E031643917729FA45EB0CE4150B76AEA660356B2D5FA8', // this FormReactor
        document.getElementById('form-rent-mi'), // ID of the web form you want to attach your FormReactor
        '8887346888', // the tracking number used for associating visitor data and caller ID consumers will see
        {
          country_code: '1',
          name: 'name', // set to the ID of the lead name field, or pass in a list of IDs if there are multiple name fields
          phone: 'phone', // ID of the lead phone number field
          email: 'email',
          fields: [
            'led_wall_size',
            'drop_of_date',
            'drop_of_time',
            'pick_up_date',
            'pick_up_time',
            'address',
            'city',
            'state',
            'zip_code',
            'comment',
          ], // list of additional field IDs in the form you want to capture
        }
      );
    });
  } else if (document.getElementById('form-rent-sf')) {
    (window.__ctm_loaded || []).push(function () {
      __ctm.form.capture(
        'app.calltrackingmetrics.com', // the capture host
        'FRT472ABB2C5B9B141A3A4E03164391772967A12F72A0EB6FC386032BFB69593000', // this FormReactor
        document.getElementById('form-rent-sf'), // ID of the web form you want to attach your FormReactor
        '8887110883', // the tracking number used for associating visitor data and caller ID consumers will see
        {
          country_code: '1',
          name: 'name', // set to the ID of the lead name field, or pass in a list of IDs if there are multiple name fields
          phone: 'phone', // ID of the lead phone number field
          email: 'email',
          fields: [
            'led_wall_size',
            'drop_of_date',
            'drop_of_time',
            'pick_up_date',
            'pick_up_time',
            'address',
            'city',
            'state',
            'zip_code',
            'comment',
          ], // list of additional field IDs in the form you want to capture
        }
      );
    });
  } else if (document.getElementById('form-rent-lv')) {
    (window.__ctm_loaded || []).push(function () {
      __ctm.form.capture(
        'app.calltrackingmetrics.com', // the capture host
        'FRT472ABB2C5B9B141A3A4E0316439177290F1306506AB73BC0F404EA410A5C6C8D', // this FormReactor
        document.getElementById('form-rent-lv'), // ID of the web form you want to attach your FormReactor
        '8886285788', // the tracking number used for associating visitor data and caller ID consumers will see
        {
          country_code: '1',
          name: 'name', // set to the ID of the lead name field, or pass in a list of IDs if there are multiple name fields
          phone: 'phone', // ID of the lead phone number field
          email: 'email',
          fields: [
            'led_wall_size',
            'drop_of_date',
            'drop_of_time',
            'pick_up_date',
            'pick_up_time',
            'address',
            'city',
            'state',
            'zip_code',
            'comment',
          ], // list of additional field IDs in the form you want to capture
        }
      );
    });
  } else if (document.getElementById('form-rent-atl')) {
    (window.__ctm_loaded || []).push(function () {
      __ctm.form.capture(
        'app.calltrackingmetrics.com', // the capture host
        'FRT472ABB2C5B9B141A3A4E0316439177298A7B92DB3868B77BD26A57E50E00D730', // this FormReactor
        document.getElementById('form-rent-atl'), // ID of the web form you want to attach your FormReactor
        '4702603642', // the tracking number used for associating visitor data and caller ID consumers will see
        {
          country_code: '1',
          name: 'name', // set to the ID of the lead name field, or pass in a list of IDs if there are multiple name fields
          phone: 'phone', // ID of the lead phone number field
          email: 'email',
          fields: [
            'led_wall_size',
            'drop_of_date',
            'drop_of_time',
            'pick_up_date',
            'pick_up_time',
            'address',
            'city',
            'state',
            'zip_code',
            'comment',
          ], // list of additional field IDs in the form you want to capture
        }
      );
    });
  } else if (document.getElementById('form-rent-ny')) {
    (window.__ctm_loaded || []).push(function () {
      __ctm.form.capture(
        'app.calltrackingmetrics.com', // the capture host
        'FRT472ABB2C5B9B141A3A4E0316439177298A7B92DB3868B77B58DF3E9AC10544DF', // this FormReactor
        document.getElementById('form-rent-ny'), // ID of the web form you want to attach your FormReactor
        '6466635940', // the tracking number used for associating visitor data and caller ID consumers will see
        {
          country_code: '1',
          name: 'name', // set to the ID of the lead name field, or pass in a list of IDs if there are multiple name fields
          phone: 'phone', // ID of the lead phone number field
          email: 'email',
          fields: [
            'led_wall_size',
            'drop_of_date',
            'drop_of_time',
            'pick_up_date',
            'pick_up_time',
            'address',
            'city',
            'state',
            'zip_code',
            'comment',
          ], // list of additional field IDs in the form you want to capture
        }
      );
    });
  } else if (document.getElementById('form-rent-hou')) {
    (window.__ctm_loaded || []).push(function () {
      __ctm.form.capture(
        'app.calltrackingmetrics.com', // the capture host
        'FRT472ABB2C5B9B141A3A4E0316439177299FFB7C1E0604F7C9B3293221751026E2', // this FormReactor
        document.getElementById('form-rent-hou'), // ID of the web form you want to attach your FormReactor
        '7135972776', // the tracking number used for associating visitor data and caller ID consumers will see
        {
          country_code: '1',
          name: 'name', // set to the ID of the lead name field, or pass in a list of IDs if there are multiple name fields
          phone: 'phone', // ID of the lead phone number field
          email: 'email',
          fields: [
            'led_wall_size',
            'drop_of_date',
            'drop_of_time',
            'pick_up_date',
            'pick_up_time',
            'address',
            'city',
            'state',
            'zip_code',
            'comment',
          ], // list of additional field IDs in the form you want to capture
        }
      );
    });
  } else if (document.getElementById('form-rent-orl')) {
    (window.__ctm_loaded || []).push(function () {
      __ctm.form.capture(
        'app.calltrackingmetrics.com', // the capture host
        'FRT472ABB2C5B9B141A3A4E0316439177299D9BB5CE8587AF28528A5B4B2EDF42D4', // this FormReactor
        document.getElementById('form-rent-orl'), // ID of the web form you want to attach your FormReactor
        '4078900996', // the tracking number used for associating visitor data and caller ID consumers will see
        {
          country_code: '1',
          name: 'name', // set to the ID of the lead name field, or pass in a list of IDs if there are multiple name fields
          phone: 'phone', // ID of the lead phone number field
          email: 'email',
          fields: [
            'led_wall_size',
            'drop_of_date',
            'drop_of_time',
            'pick_up_date',
            'pick_up_time',
            'address',
            'city',
            'state',
            'zip_code',
            'comment',
          ], // list of additional field IDs in the form you want to capture
        }
      );
    });
  } else if (document.getElementById('form-rent-phx')) {
    (window.__ctm_loaded || []).push(function () {
      __ctm.form.capture(
        'app.calltrackingmetrics.com', // the capture host
        'FRT472ABB2C5B9B141A3A4E0316439177299D9BB5CE8587AF28AD8C4C5BDAC0B873', // this FormReactor
        document.getElementById('form-rent-phx'), // ID of the web form you want to attach your FormReactor
        '6238877893', // the tracking number used for associating visitor data and caller ID consumers will see
        {
          country_code: '1',
          name: 'name', // set to the ID of the lead name field, or pass in a list of IDs if there are multiple name fields
          phone: 'phone', // ID of the lead phone number field
          email: 'email',
          fields: [
            'led_wall_size',
            'drop_of_date',
            'drop_of_time',
            'pick_up_date',
            'pick_up_time',
            'address',
            'city',
            'state',
            'zip_code',
            'comment',
          ], // list of additional field IDs in the form you want to capture
        }
      );
    });
  } else if (document.getElementById('form-rent-laa')) {
    (window.__ctm_loaded || []).push(function () {
      __ctm.form.capture(
        'app.calltrackingmetrics.com', // the capture host
        'FRT472ABB2C5B9B141A3A4E0316439177292AADCBE01C8EF97607FD61F9DEA6B7B7', // this FormReactor
        document.getElementById('form-rent-laa'), // ID of the web form you want to attach your FormReactor
        '8773052555', // the tracking number used for associating visitor data and caller ID consumers will see
        {
          country_code: '1',
          name: 'name', // set to the ID of the lead name field, or pass in a list of IDs if there are multiple name fields
          phone: 'phone', // ID of the lead phone number field
          email: 'email',
          fields: [
            'led_wall_size',
            'drop_of_date',
            'drop_of_time',
            'pick_up_date',
            'pick_up_time',
            'address',
            'city',
            'state',
            'zip_code',
            'comment',
          ], // list of additional field IDs in the form you want to capture
        }
      );
    });
  } else if (document.getElementById('form-rent-la-b')) {
    (window.__ctm_loaded || []).push(function () {
      __ctm.form.capture(
        'app.calltrackingmetrics.com', // the capture host
        'FRT472ABB2C5B9B141A3A4E03164391772976C2ECF68D620062E966811BAD49DEE0', // this FormReactor
        document.getElementById('form-rent-la-b'), // ID of the web form you want to attach your FormReactor
        '8773052555', // the tracking number used for associating visitor data and caller ID consumers will see
        {
          country_code: '1',
          name: 'name', // set to the ID of the lead name field, or pass in a list of IDs if there are multiple name fields
          phone: 'phone', // ID of the lead phone number field
          email: 'email',
          fields: [
            'led_wall_size',
            'drop_of_date',
            'drop_of_time',
            'pick_up_date',
            'pick_up_time',
            'address',
            'city',
            'state',
            'zip_code',
            'comment',
            'form-video',
          ], // list of additional field IDs in the form you want to capture
        }
      );
    });
  } else if (document.getElementById('voucher-form')) {
    (window.__ctm_loaded || []).push(function () {
      __ctm.form.capture(
        'app.calltrackingmetrics.com', // the capture host
        'FRT472ABB2C5B9B141A3A4E0316439177291F88251A0BF85F91C05D00A614879C9A', // this FormReactor
        document.getElementById('voucher-form'), // ID of the web form you want to attach your FormReactor
        '8773052555', // the tracking number used for associating visitor data and caller ID consumers will see
        {
          country_code: '1',
          name: 'name', // set to the ID of the lead name field, or pass in a list of IDs if there are multiple name fields
          phone: 'phone', // ID of the lead phone number field
          email: 'email',
          fields: ['activate'], // list of additional field IDs in the form you want to capture
        }
      );
    });
  }
}, 1500);
